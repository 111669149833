import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_pro.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`1) Quality`}</h4>
    <p>{`Make sure you have good .gpx data. Enough data points to show a round and
smooth line (not angled straights). No parts with signal drift or skipping.
No parts where you took the wrong way recording it. Elevation data must be
included to automatically generate the height profile.`}</p>
    <br /><br />
    <h4>{`2) Redundancy`}</h4>
    <p>{`Before you upload a trail, check if it’s already on the map. If it overlaps
an existing trail for a short section (10%) it’s ok. Cut the trail if it
overlaps more.`}</p>
    <br />
    <br />
    <Link className="float-left" to="/pro/activitytype" mdxType="Link">
  ← Select the type of activity
    </Link>
    <Link className="float-right" to="/pro/description" mdxType="Link">
  Trail description and details →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      